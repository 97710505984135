@font-face {
  font-family: 'geomanist';
  src: url('./fonts/geomanist-regular-webfont.eot');
  src: url('./fonts/geomanist-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/geomanist-regular-webfont.woff2') format('woff2'),
    url('./fonts/geomanist-regular-webfont.woff') format('woff'),
    url('./fonts/geomanist-regular-webfont.ttf') format('truetype'),
    url('./fonts/geomanist-regular-webfont.svg#geomanistregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'geomanist';
  src: url('./fonts/geomanist-medium-webfont.eot');
  src: url('./fonts/geomanist-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/geomanist-medium-webfont.woff2') format('woff2'),
    url('./fonts/geomanist-medium-webfont.woff') format('woff'),
    url('./fonts/geomanist-medium-webfont.ttf') format('truetype'),
    url('./fonts/geomanist-medium-webfont.svg#geomanistregular') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'geomanist';
  src: url('./fonts/geomanist-book-webfont.eot');
  src: url('./fonts/geomanist-book-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/geomanist-book-webfont.woff2') format('woff2'),
    url('./fonts/geomanist-book-webfont.woff') format('woff'),
    url('./fonts/geomanist-book-webfont.ttf') format('truetype'),
    url('./fonts/geomanist-book-webfont.svg#geomanistregular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

/* quill wysiwyg snow theme overrides */
.ql-toolbar.ql-snow {
	border: none !important;
	border-bottom: 1px solid #ccc !important;
}

.ql-container.ql-snow {
	border: none !important;
}

/* quill wysiwyg content */
.ql-editor {
	font-family: 'Helvetica Neue', Arial, sans-serif;
	font-size: 14px;
	line-height: 1.6;
	color: #000000;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	font-weight: bold !important;
	color: #000000 !important;
}

.ql-editor h1 {
	font-size: 2em !important;
	margin: 0 0 0.67em 0 !important;
}

.ql-editor h2 {
	font-size: 1.75em !important;
	margin: 0 0 0.83em 0 !important;
}

.ql-editor h3 {
	font-size: 1.5em !important;
	margin: 0 0 1em 0 !important;
}

.ql-editor h4 {
	font-size: 1.25em !important;
	margin: 0 0 1.33em 0 !important;
}

.ql-editor h5 {
	font-size: 1em !important;
	margin: 0 0 1.67em 0 !important;
}

.ql-editor h6 {
	font-size: 0.875em !important;
	margin: 0 0 2.33em 0 !important;
}

.ql-editor p {
	margin: 0 0 1.2em 0 !important;
	color: #000000 !important;
}

.ql-editor ul,
.ql-editor ol {
	margin: 0 0 1em 0 !important;
	padding-left: 40px !important;
}

.ql-editor ul li,
.ql-editor ol li {
	margin-bottom: 0.5em !important;
}

.ql-editor a {
	color: #1e90ff !important;
	text-decoration: underline !important;
}

.ql-editor a:hover {
	text-decoration: underline !important;
}